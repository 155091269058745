<!-- 运维工单 -->
<template>
  <w-order :type="2"/>
</template>

<script>
import WOrder from './componenets/index';
export default {
  components: {WOrder},
  data () {
    return {
    };
  },
  methods: {}
}

</script>
<style lang='scss' scoped>
</style>